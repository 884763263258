
import { defineComponent } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import InfoModal from '@/components/modals/forms/entity/InfoModal.vue';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import axios from 'axios';
import { ElNotification } from 'element-plus';
import { apiEndpoint } from '@/mixin/apiEndpoint.js';

export default defineComponent({
   mixins: [apiEndpoint],
  name: 'stipend-report-district',
  components: {
    Datatable,
    InfoModal,
  },
  data() {
    return {
      tableHeader: [
        {
          name: 'sl',
          key: 'sl',
          sortable: true,
        },
        {
          name: 'Association',
          key: 'association',
          sortable: true,
        },
        {
          name: 'Male',
          key: 'male',
          sortable: true,
        },
        {
          name: 'Female',
          key: 'female',
          sortable: true,
        },
        {
          name: 'Total',
          key: 'total',
          sortable: true,
        },
        // {
        //   name: "Actions",
        //   key: "actions",
        //   sortable: false,
        // },
      ],
      lists: [],
      search: '',
      tableData: [
        {
          sl: 1,
          association: 'XYZ',
          male: 20,
          female: 30,
          total: 50,
        },
        {
          sl: 2,
          association: 'YUS',
          male: 20,
          female: 30,
          total: 50,
        },
      ],
      data: [] as any,
      total: [] as any,
      totalmale: '' as any,
      totalfemale: '' as any,
      totaltrainee: '' as any,
      load: false,
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
       async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      await ApiService.post('stipend/activity-report-districtpdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async printPdf() {
      let formData = new FormData();

      let data = `${this.VUE_APP_API_URL}/api/stipend/activity-report-districtprint`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },

    async exportTrainee() {
      let formData = new FormData();
      
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/stipend/activity-report-districtexcel`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Enrollment_Trainee List.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getData() {
      this.load = true;
      ApiService.get('stipend/activity-report-district')
        .then((response) => {
          if ((response.data.status = 'success')) {
            this.data = response.data.data.data;
            this.total = response.data.data.total;
            this.totalmale = response.data.data.total_male;
            this.totalfemale = response.data.data.total_female;
            this.totaltrainee = response.data.data.total_trainee;
            this.load = false;
          }
        })
        .catch((response) => {
          console.log(response);
          this.load = false;
        });
    },
  },
});
